#_ideogram.faint .gneg,
#ideo-container .band {
    fill: rgba(255, 255, 255, 0) !important;
}
#_ideogram .chrLabel,
#_ideogram .annot {
    cursor: default !important;
}

._ideogramTooltip{
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white;
}
